<template>
  <div>Домашняя страница</div>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style></style>
